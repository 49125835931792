import Image, {ImageLoaderProps, ImageProps} from "next/legacy/image";
import React, {memo} from "react";
import nextImageSource, {ImageExtension} from "src/utils/nextImageSource";

import {dev} from "../_common/_constants";

const cloudflareloaderRootUrl = process.env.CLOUDFLARELOADER_ROOT_URL;

// @ts-expect-error TS7006: Parameter 'src' implicitly has an 'any' type.
const normalizeSrc = src => (src[0] === "/" ? src.slice(1) : src);

export const cloudflareLoader = ({src, width, quality}: ImageLoaderProps) => {
  const params = [`width=${width}`];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(",");
  return `${cloudflareloaderRootUrl || ""}/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};

export type NextImageProps = ImageProps & {extension?: ImageExtension};

/**
 @deprecated use `<Image/>` from `@components/Image` or direct import from `next/image` instead
 */
const NextImage: React.FC<NextImageProps> = memo(({src, extension = "jpg", className, ...rest}) => {
  const layoutClass = "layout" in rest && rest.layout === "fill" ? "h100p obfc" : "";

  const classes = `pos-a top0 right0 bottom0 left0 minw100p minh100p ${layoutClass} ${
    className || ""
  }`;

  const srcStr = src as string;

  // isFullPath = true : if an external
  const isFullPath = srcStr.startsWith("http");

  const source = isFullPath ? src : nextImageSource(srcStr, extension);

  const shouldUseLoader = !dev || cloudflareloaderRootUrl;
  if (extension === "svg") {
    return (
      <img
        alt={rest.alt || (source as string)}
        className={className}
        src={source as string}
        {...rest}
      />
    );
  }
  return (
    <Image
      {...rest}
      {...(shouldUseLoader && {loader: cloudflareLoader})} // don't use cloudflare if dev
      priority={rest.priority} // always show images in CI
      className={classes}
      src={source}
    />
  );
});

NextImage.displayName = "NextImage";

export default NextImage;
