import React, {useEffect} from "react";

export const useOnClickOutside = (
  boundaryElement: HTMLElement | null | React.MutableRefObject<HTMLElement | null>,
  onClickOutside: (e: MouseEvent) => unknown,
) => {
  useEffect(() => {
    const getNode = () =>
      boundaryElement instanceof HTMLElement ? boundaryElement : boundaryElement?.current;

    const isValidOutsideClick = (e: MouseEvent) => {
      const node = getNode();
      return node && e.target instanceof Node && !node.contains(e.target);
    };

    const eventHandler = (e: MouseEvent) => {
      if (isValidOutsideClick(e)) {
        onClickOutside(e);
      }
    };

    document.addEventListener("mousedown", eventHandler);

    return () => {
      document.removeEventListener("mousedown", eventHandler);
    };
  }, [boundaryElement, onClickOutside]);
};
