import {FOCUSABLE_ELEMENT_SELECTOR} from "./constants";

const getAllFocusableElements = () =>
  Array.from(document.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENT_SELECTOR));

export const getFocusableElements = (el: HTMLElement) =>
  Array.from(el.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENT_SELECTOR));

export const getFocusableChildren = (el: HTMLElement) =>
  getFocusableElements(el).filter(focusable => focusable !== el);

export const getFirstFocusableChild = (el: HTMLElement) => {
  const queried = el.querySelector(FOCUSABLE_ELEMENT_SELECTOR);
  return queried instanceof HTMLElement ? queried : null;
};

export const getLastFocusableChild = (el: HTMLElement) => {
  const focusableEles = getFocusableElements(el);
  return focusableEles[focusableEles.length - 1] || null;
};

const prevOrLast = (index: number, length: number) => (index - 1 < 0 ? length - 1 : index - 1);
const nextOrFirst = (index: number, length: number) => (index + 1 <= length - 1 ? index + 1 : 0);

const getFocusableAfter = (el: HTMLElement, focusables: HTMLElement[]) => {
  const focusedIndex = focusables.indexOf(el);
  if (focusedIndex === null || focusedIndex === undefined || focusedIndex < 0) return null;
  return focusables[nextOrFirst(focusedIndex, focusables.length)];
};

const getFocusableBefore = (el: HTMLElement, focusables: HTMLElement[]) => {
  const focusedIndex = focusables.indexOf(el);
  if (focusedIndex === null || focusedIndex === undefined || focusedIndex < 0) return null;
  return focusables[prevOrLast(focusedIndex, focusables.length)];
};

/**
 * Returns the focusable element after the document's focused element.
 */
const getNextFocusable = (focusables: HTMLElement[]) => {
  const focusedEle = document.activeElement;
  return focusedEle instanceof HTMLElement ? getFocusableAfter(focusedEle, focusables) : null;
};

/**
 * Returns the focusable element before the document's focused element.
 */
const getPrevFocusable = (focusables: HTMLElement[]) => {
  const focusedEle = document.activeElement;
  return focusedEle instanceof HTMLElement ? getFocusableBefore(focusedEle, focusables) : null;
};

export const getNextElementInTabIndex = (
  /**
   * The node from which to look for. This defaults to the document's `activeElement`
   */
  previousNode?: HTMLElement,
  /**
   * A node to exclude from the possible results.
   */
  excludeNode?: HTMLElement | null,
) => {
  const node = previousNode || document.activeElement;
  if (!(node instanceof HTMLElement)) return null;
  const blackList = excludeNode ? getFocusableElements(excludeNode) : [];
  const allFocusables = getAllFocusableElements().filter(node => !blackList.includes(node));
  return getFocusableAfter(node, allFocusables);
};

export const getNextFocusableChild = (el: HTMLElement) => {
  const focusableEles = getFocusableChildren(el);
  return getNextFocusable(focusableEles);
};

export const getPreviousFocusableChild = (el: HTMLElement) => {
  const focusableEles = getFocusableChildren(el);
  return getPrevFocusable(focusableEles);
};
